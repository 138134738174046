const messages = {
  tr: {
    cancel: 'İptal',
    send: 'Gönder',
    sending: 'Gönderiliyor...',
    name: 'İsim',
    email: 'Email',
    last_name: 'Soyisim',
    please_wait: 'Lütfen Bekleyin',
    registration: 'Giriş',
    city: 'Şehir',
    'country/region': 'Ülke',
    'zip/postal_code': 'Posta Kodu',
    'state/province': 'Bölge',
    phone: 'Telefon Numarası',
    address: 'Adres',
    speciality: 'Uzmanlık',
    organization: 'Kurum',
    job_title: 'Unvan',

    event_password: 'Etkinlik Şifresi',
    no_event_found: 'Etkinlik bulunamadı',
    okey: 'Tamam',
    error: 'Hata!',
    join_now: 'Hemen Katılın',
    page_language: 'Sayfa dilini değiştirin',
    type_your_question: 'Sorunuzu bu alana yazabilirsiniz.',
    this_event_is_not_started: 'Etkinlik henüz başlamadı.',
    registration_required:
      'Bu event için kayıt olmak zorunludur. Kayıt sayfasına yönlendiriliyorsunuz',
    thankyou_registration: 'Etkinliğimize kayıt olduğunuz için teşekkür ederiz!',
    link_sent: 'Size özel oluşturulan giriş linkiniz email adresinize gönderilmiştir.',
    link_warning:
      'Lütfen e-posta gelen kutunuzu kontrol ediniz. Yayın linkini, e-posta gelen kutunuzda göremiyorsanız lütfen <b>“önemsiz posta”</b> klasörünü kontrol ediniz',
    tc_kimlik_no: 'TC Kimlik No',
    dogum_yılı: 'Doğum Yılı',
  },
};

export default messages;
