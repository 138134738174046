const messages = {
  ro: {
    cancel: 'Anulare',
    send: 'Trimite',
    sending: 'Se trimite...',
    name: 'Prenume',
    last_name: 'Nume',
    error: 'Error!',
    okey: 'Bine',
    email: 'E-mail',
    event_password: 'Parola evenimentului',
    registration: 'Înregistrarea',
    city: 'Orașul',
    'country/region': 'Țara',
    'zip/postal_code': 'Codul postal',
    'state/province': 'Statut',
    phone: 'Numărul de telefon',
    address: 'Adresa',
    speciality: 'Specialitatea',
    organization: 'Organizația (Instituția)',
    job_title: 'Denumirea funcției',
    please_wait: 'Vă rog așteptați',
    no_event_found: 'Nu a fost găsit niciun eveniment',
    join_now: 'Alătură-te acum',
    page_language: 'Limba site-ului',
    type_your_question: 'Vă rugăm să introduceți întrebarea dvs',
    this_event_is_not_started: 'Evenimentul încă nu a început',
    this_event_completed: 'Evenimentul a fost încheiat',
    registration_required: 'Pentru acest eveniment este necesară înregistrarea',
    thankyou_registration: 'Vă mulțumim pentru înregistrarea webcast-ului nostru',
    link_sent: 'Un link privat pentru webcast a fost trimis la e-mailul dvs',
    link_warning:
      'Please check your mailbox. If you do not receive the confirmation message within a few minutes, please check your Spam folder just in case the confirmation email got delivered there instead of your inbox.',
  },
};

export default messages;
